
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

@font-face {
	font-family: 'Manrope-ExtraLight'; 
	src: url(../src/fonts/Manrope-ExtraLight.ttf); 
}

@font-face {
	font-family: 'Manrope-Light'; 
	src: url(../src/fonts/Manrope-Light.ttf); 
}

@font-face {
	font-family: 'Manrope-Regular'; 
	src: url(../src/fonts/Manrope-Regular.ttf); 
}

@font-face {
	font-family: 'Manrope-Medium'; 
	src: url(../src/fonts/Manrope-Medium.ttf); 
}

@font-face {
	font-family: 'Manrope-Semibold'; 
	src: url(../src/fonts/Manrope-SemiBold.ttf); 
}

@font-face {
	font-family: 'Manrope-Bold'; 
	src: url(../src/fonts/Manrope-Bold.ttf); 
}

@font-face {
	font-family: 'Manrope-Extrabold'; 
	src: url(../src/fonts/Manrope-ExtraBold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Light'; 
	src: url(../src/fonts/Montserrat-Light.ttf); 
}

@font-face {
	font-family: 'Montserrat-Regular'; 
	src: url(../src/fonts/Montserrat-Regular.ttf); 
}

@font-face {
	font-family: 'Montserrat-Medium'; 
	src: url(../src/fonts/Montserrat-Medium.ttf); 
}

@font-face {
	font-family: 'Montserrat-Semibold'; 
	src: url(../src/fonts/Montserrat-Semibold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Bold'; 
	src: url(../src/fonts/Montserrat-Bold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Extrabold'; 
	src: url(../src/fonts/Montserrat-Extrabold.ttf); 
}

@media print {
    html, body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
        background-size: cover;
    }

    .print {
        margin: 20px;
    }

    .print * {
        page-break-before: auto;
        page-break-after: auto;
    }
}

* { -webkit-print-color-adjust: exact; }

body, body * {
    font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, с 1999 года*/
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    margin: unset;
 }

.logoheader {
    background: #005687;
    padding: 20px 8px 20px 8px;
}

.logo {
    height: 50px;
    background: url(../src/img/demologo.png) no-repeat center center;
    background-size: contain;
}

.logomenu {
    height: 100px;
    margin: 30px 8px 10px 8px;
    background: url(../src/img/demologo.png) no-repeat center center;
    background-size: contain;
}

.authlogo {
    display: flex;
    height: 180px;
    width: 100%;
    position: relative;
    top: 30px;
    background: url(../src/img/demologo.png) no-repeat center center;
    background-size: contain;
}

.ant-menu {
    background: transparent;
    color: #ffffff;
}

.ant-menu, .ant-menu * {
    font-family: "Montserrat-Medium" !important;
}

.dividerwhite {
    border-top: 1px solid rgb(255, 255, 255);
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-item-selected {
    color: #ffffff;
    background: #ffffff1a;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: rgb(230 230 255 / 2%);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff14;
}

.ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.mobilevis {
    display: none;
}

.listscroll {
    overflow: auto;
}

.headerfull {
    background: rgb(6 62 130 / 25%);
    -webkit-box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
    box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
}

.revfull {
    min-height: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datepickerfull {
    width: 100%;
}

.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #001a29;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #002438;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
}

.nomargin {
    margin:0 !important
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.max400 {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
}

.max500 {
    max-width: 500px !important;
    margin-left: auto;
    margin-right: auto;
}

.listanswer {
    padding: 2px 10px;
    background: #edf6ff;
    border-radius: 30px;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:10px;
    position:relative;
    top:180px;
    opacity: 0;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(247, 247, 247, .75))
}

.maxtask::after {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
    padding: 20px;
    top: 0;
    left: 0;
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffeaad 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe1f1 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.lasttask {
    padding: 0 20px 20px 20px;
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
    height: 100%;
}

.scrolltable {
    max-height: 200px;
    overflow: hidden;
}

.floattask {
    display: flex;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 1;
}

.floattask > span {
    cursor: pointer;
    background: url(../src/img/taskadd.svg) no-repeat center center;
    background-size: contain;
    width: 60px;
    height: 60px;
    transition: all .3s ease;
}

.floattask > span:hover {
    transform: scale(1.05);
}

.tasks_theme {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}

.tasks_progress {
    font-size: 12px;
}

.theme {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
    font-family: "Montserrat-Semibold" !important;
    font-size: 17px;
    flex-wrap: wrap;
}

.maxtask > div{
    color: #2b2b2b;
    font-family: "Montserrat-Medium" !important;
    font-size: 16px;
}

.taskheader {
    padding: 15px 0;
    display: flex;
    background: #014f7b;
    border-radius: 30px 30px 0 0;
}

.taskheader * {
    font-family: "Montserrat-Semibold" !important;
}

.tasktop {
    z-index: 100;
    margin-left: auto;
}

.elementlock ~ * {
    z-index: 3;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #fafafa8f !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #ffffffba !important;
}

.ant-tabs-tab {
    border-radius: 20px 20px 5px 5px !important;
}

.c_patients .ant-card {
    border-radius: 20px;
}

.cardlist {
    align-items: stretch;
}

.cardlist .ant-card {
    height: 100%;
}

.ant-card {
    display: flex;
    flex-direction: column;
}

.cardlist > * {
    width: 350px;
}

.cardlist .ant-card-actions {
    margin-top: auto;
}

.table-white {
    background: #ffffff !important;
    border-radius: 30px !important;
}

.ant-table {
    background: transparent;
}

.elementlock > * {
    padding-left: 20px;
    padding-right: 20px;
}

.margintopbottom {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margintopbottom>div:nth-child(3) {
    margin-left: 10px;
}

.taskhide .ant-table-cell input {
    color: #484848;
    font-family: 'Montserrat-Medium' !important;
    font-size: 16px;
}

.taskhide .ant-checkbox-inner {
    border: 1px solid #484848 !important;
}

.taskhide {
    opacity: 1;
    padding: 0;
    cursor: pointer;
    transition: all .3s ease;
    min-height: 200px;
}

.taskhide:first-of-type:last-child {
    max-width: 500px;
}

.ant-dropdown-menu-item:first-of-type {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ant-dropdown-menu-item:last-of-type {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ant-dropdown-menu {
    border-radius: 15px;
    padding: 0;
}

.taskhide:nth-child(4n+1):last-child {
    max-width: 500px;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.ant-btn-primary {
    border-radius: 30px;
}

.ant-btn-dashed {
    border-radius: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: none;
    text-align: center;
}

.ant-pagination {
    text-align: center;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.badgemenu {
    margin-right: 5px;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.listfull {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.listnofull {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    visibility: visible;
    z-index: 99999999;
    list-style-type: none;
}

.listnofull>div:nth-child(1) {
    margin-right: 10px;
}

.listnofull>div:nth-child(3) {
    width: 100%;
}

.w100 > .ant-space-item:nth-child(2) {
    width: 100%;
}

.taskhide:hover {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}

.ant-popover-inner {
    border-radius: 15px;
}

.ant-alert {
    border-radius: 20px !important;
}

.fcenter {
    text-align: center;
    display: flex;
    justify-content: center;
}

.fright {
    text-align: center;
    display: flex;
    justify-content: flex-end;
}

.authp {
    margin-bottom: 20px;
}

.authp u {
    font-size: 12px;
    cursor: pointer;
    text-decoration-color: #ffffff;
    color: #ffffff;
}

.filters {
    opacity: 0;
}

.shadowscreen {
    -webkit-animation: shadowscreen 3s infinite ease-in-out;
    animation: shadowscreen 3s infinite ease-in-out;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 20%);
    background: rgba(247, 247, 247, 1);
    border-radius: 30px;
}

@keyframes shadowscreen {
    0% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
    50% {
        -webkit-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.authform {
    justify-content: center;
}

.ant-avatar > img, .ant-avatar .ant-image-img {
    border-radius: 50%;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.jpg) fixed no-repeat top center !important;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

.filtersvisible {
    position: absolute;
    opacity: 0;
    top: -1000px;
    width: 0;
    height: 0;
}

.filtersmargin {
    margin-right: 16px;
}

.cashblock {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cashtitle > * {
    font-family: 'Montserrat-SemiBold' !important;
    font-size: 16px;
    color: #000000;
}

.cashcount > * {
    font-family: 'Montserrat-SemiBold' !important;
    color: #07609a;
}

.cashicon {
    background: url(../src/img/cash.svg) no-repeat;
    height: 80px;
    width: 80px;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
}

.lglobal::before, .loadpage::before {
    background: url(../src/img/bg_main_demo.jpg) no-repeat top,#ffffff;
    background-size: cover;
    background-attachment: fixed;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    display: block;
}

.ant-drawer-content-wrapper {
    will-change: transform;
}

.ant-modal-wrap {
    will-change: auto;
}

.ant-drawer-content {
    background-color: #005687;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 16px 0 32px;
}

.ant-layout-content {
    z-index: 1;
}

.ant-layout-sider {
    background-color: #005687;
    color: #ffffff;
    border-right: 1px solid #ffffff;
}

.inputtrans * {
    color: #484848;
}

.ant-select-arrow * {
    color: #024e79 !important;
}

.whitebut * {
    border-color: #ffffff !important;
}

.bluebut .ant-btn {
    background: #024e79 !important;
    color: #ffffff;
}

.bluebut * {
    border-color: #024e79 !important;
}

.bluebut .ant-btn:hover, .bluebut .ant-btn:focus {
    color: #ffffff;
}

.whitebut .ant-btn:hover, .whitebut .ant-btn:focus {
    color: #000000;
}

.inputtrans .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ffffff;
}

.inputtrans .ant-input-affix-wrapper, .inputtrans .ant-select-selector {
    border: 1px solid #ffffff;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.inputtrans .ant-btn {
    border-color: #ffffff;
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.bnone {
    border: transparent !important;
}

.ant-drawer-body {
    padding: 0;
}

.menubutton {
    position: fixed;
    display: flex;
    left: 0;
    top: 22px;
    left: 22px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 8px;
    background: #ffffff;
    color: #6083a3;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.tworow {
    display: flex;
    flex-wrap: wrap;
}

.tworow > div {
    width: 50%;
    min-width: 50%;
}

.hoverlist .ant-list-item {
    transition: all .3s ease;
    cursor: pointer;
}

.hoverlist .ant-list-item:hover {
    transform: translateY(5px);
}

.modal_new > .ant-modal-content{
    background: #ffffff !important;
}

.filters_center .ant-space-item:last-child {
    margin: auto;
}

.modal_newtitle {
    font-family: 'Manrope-Bold' !important;
    font-size: 16px;
    text-align: center;
    color: #024e79;
    margin-bottom: 20px;
}

.modal-mini {
    max-width: calc(100vw - 10%);
}

.modal-mini .ant-modal-header {
    display: none;
}

.modal-mini > .ant-modal-content {
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 30%);
    background: rgb(220,224,250);
    background: linear-gradient(0deg, rgba(220,224,250,1) 0%, rgba(229,232,242,1) 20%, rgba(229,232,242,1) 80%, rgba(196,200,228,1) 100%);
    background-position-x: right;
}

.ant-form-item-label > label {
    color: #484848;
    font-family: 'Montserrat-Regular' !important;
}

.col_24 {
    margin: 0 20px;
}

.modal-mini .ant-form-item-label > label {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
}

.modal-mini-header {
    padding-top: 20px;
    text-align: center;
}

.modal-mini-body {
    padding: 24px;
}

.modal-mini  .ant-modal-body {
    padding: 0;
}

.modal-mini-header::after {
    content: "";
    display: block;
    height: 1px;
    background: #97bee1;
    margin-bottom: 30px;
}

.modal-mini-header-title {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
    font-size: 16px;
    margin: 10px;
}

.modal-mini-header-title.title-big {
    font-size: 22px;
}

.modal-mini .ant-list-item strong {
    color: #355b7d
}

.des-button {
    background: #024e79;
    color: #ffffff;
    border: none;
    box-shadow: 0 0px 15px 0 rgb(0 0 0 / 20%);
}

.des-button > * {
    font-family: 'Montserrat-Regular' !important;
}

.des-button.ant-btn-dangerous {
    background: rgb(240, 160, 160);
    background: linear-gradient(180deg, rgb(240, 160, 160) 0%, rgb(190, 78, 78) 100%);
}

.des-green {
    background: rgb(115, 142, 94);
    background: linear-gradient(165deg, rgb(115, 142, 94) 0%, rgb(91, 115, 72) 100%);
}

.des-button:hover, .des-button:focus {
    background: linear-gradient(165deg, rgb(42, 98, 135) 0%, rgb(71, 95, 140)100%) !important;
    color: #ffffff !important;
}

.des-green:hover, .des-green:focus {
    background: linear-gradient(165deg, rgb(99, 124, 81) 0%, rgb(83, 104, 65)100%) !important;
    color: #ffffff !important;
}

.des-button.ant-btn-dangerous:hover, .des-button.ant-btn-dangerous:focus {
    background: linear-gradient(180deg, rgb(216, 132, 132) 0%, rgb(163, 62, 62) 100%) !important;
    color: #ffffff !important;
}

.modal-mini .ant-input-affix-wrapper, .modal-mini .ant-input-affix-wrapper, .modal-mini .ant-picker, .modal-mini .ant-select:not(.ant-select-customize-input) .ant-select-selector, .modal-mini .ant-form-item-control-input-content > .ant-input, .modal-mini textarea.ant-input, .ant-input-number {
    border: 1px solid #97bee1 !important;
    -webkit-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    -moz-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
}

.ant-form-vertical .ant-form-item-label {
    margin-left: 10px;
}

.newpass {
    color: #ffffff;
    font-family: 'Montserrat-Semibold' !important;
    cursor: pointer;
}

.newpass:hover {
    text-decoration: underline;
}

.ant-form-vertical .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-form-vertical .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 6px;
    color: #f1134c;
    font-size: 10px;
    font-family: 'Montserrat-Semibold' !important;
    line-height: 1;
    content: 'обязательное поле';
}

.ant-picker-cell::before {
    background: #00ff260a;
}

.ant-picker-cell-disabled::before {
    background: #ff00000a;
}

.title-color {
    color: #ffffff !important;
    margin-bottom: 20px !important;
}

.table-padding {
    padding: 20px 0;
}

.table-padding .ant-pagination {
    margin: 16px;
}

.ant-table-content {
    border-radius: 30px 30px 0 0;
}

.table-padding {
    padding: 0;
}

.card_no_padding {
    border-radius: 20px;
}

.header_act {
    display: flex;
    color: #7e7e7e;
}

.header_act > div:last-of-type {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.header_act > div:first-of-type {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: 'Manrope-Semibold' !important;
}

.vis_but {
    color: #97BEE1;
    border: 1px solid #97BEE1;
    padding: 1px 5px;
    border-radius: 50px;
}

.body_card_price {
    border: 2px solid #85acd2;
    right: 0;
    position: absolute;
    bottom: 10px;
    border-right: none;
    border-radius: 50px 0 0 50px;
    padding: 2px 5px 2px 10px;
    color: #689ccc;
    font-family: 'Manrope-Semibold' !important;
    margin-bottom: 5px;
}

.body_card {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - 80px);
}

.body_card_text {
    padding: 10px;
    max-height: 150px;
    overflow: auto;
    padding-bottom: 35px;
}

.body_card_bottom {
    display: flex;
    margin-left: 10px;
    position: absolute;
    bottom: 10px;
}

.card_no_padding .ant-card-body {
    padding: 0;
    height: 100%;
}

.m-r, .m-r > * {
    font-family: 'Montserrat-Regular' !important;
}

a.ant-typography, .ant-typography a {
    color: #024e79;
}

.yescolor .ant-table-thead {
    -webkit-box-shadow: 0px 12px 10px 0px rgba(22, 60, 94, 0.2);
    -moz-box-shadow: 0px 12px 10px 0px rgba(22, 60, 94, 0.2);
    box-shadow: 0px 12px 10px 0px rgba(22, 60, 94, 0.2);
    background: rgb(50,110,150);
    background: linear-gradient(165deg, rgba(50,110,150,1) 0%, rgba(87,113,162,1) 100%);
}

.yescolor .ant-table-thead > tr > th {
    background: unset;
    font-family: 'Manrope-Semibold' !important;
    color: #ffffff;
}

.yescolor .ant-table-pagination {
    padding: 0 10px;
}

.profile_name {
    color: #ffffff;
    padding: 0 16px 0 16px;
    text-align: center;
    font-size: 16px;
}

.class_print > * {
    max-width: max-content;
}

.cards_print {
    background: #ffffff;
}

.cards_header {
    display: flex;
    align-items: center;
}

.cards_logo {
    height: 70px;
    width: 300px;
    background: url(../src/img/cards_logo.svg) no-repeat center center;
    background-size: contain;
    margin-right: auto;
}

.cards_visit {
    position: relative;
    width: 50%;
    display: flex;
}

.cards_text {
    position: relative;
    width: 100%;
    display: flex;
}

.cards_visit_text {
    background: #ffffff;
    width: fit-content;
    position: relative;
    padding-right: 5px;
}

.cards_visit_1 {
    margin-left: auto;
    background: #ffffff;
    width: fit-content;
    position: relative;
}

.cards_visit_2 {
    background: #ffffff;
    width: fit-content;
    position: relative;
}

.cards_margin_bottom_20 {
    margin-bottom: 20px;
}

.cards_visit_name {
    position: relative;
    min-width: 100px;
}

.cards_visit_name:before {
    font-family: 'Montserrat-Medium' !important;
    position: absolute;
    bottom: -18px;
    width: 100%;
    display: inline-block;
    text-align: center;
    content: 'Ф.И.О';
}

.cards_visit_right_date {
    position: relative;
    min-width: 100px;
}

.cards_visit_right_date:before {
    font-family: 'Montserrat-Medium' !important;
    position: absolute;
    bottom: -18px;
    width: 100%;
    display: inline-block;
    text-align: center;
    content: 'Дата';
}

.cards_visit_center {
    margin: auto;
}

.cards_visit_left {
    margin-right: auto;
}

.cards_visit_date_text {
    margin-right: auto;
}

.cards_visit_date:before {
    max-width: 300px !important;
}

.cards_visit_cost, .cards_visit_date {
    margin-top: 15px;
}

.cards_visit_cost:before {
    max-width: 450px !important;
}

.cards_visit_date:after {
    font-family: 'Montserrat-Medium' !important;
    position: absolute;
    bottom: -18px;
    display: inline-block;
    width: 100%;
    max-width: 450px;
    text-align: center;
    content: 'Дата';
}

.cards_mm, .cards_mm * {
    font-family: 'Montserrat-Medium' !important;
    font-size: 13px;
}

.cards_title {
    background: #004F9F;
    color: #ffffff;
    text-align: center;
    font-family: 'Montserrat-Bold' !important;
    text-transform: uppercase;
    font-size: 24px;
}

.cards_visit:before, .cards_text:before {
    position: absolute;
    bottom: 0px;
    display: inline-block;
    width: 100%;
    height: 1px;
    content: '';
    background-color: #4F4F4F;
}

.cards_title_spec {
    margin-top: 20px;
    color: #004F9F;
    text-align: center;
    font-family: 'Montserrat-Bold' !important;
    font-size: 18px;
}

.cards_spec_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cards_spec_item {
    margin: 10px;
    width: 100%;
    max-width: calc((100% - 120px ) / 6);
}

.cards_spec_avarar {
    position: relative;
}

.cards_spec_avarar > img {
    width: 100%;
    max-width: 100%
}

.cards_spec_name {
    margin-top: 10px;
    color: #004F9F;
    text-align: center;
    font-family: 'Montserrat-Bold' !important;
    font-size: 10px;
    width: min-content;
    margin: 10px auto 0 auto;
}

.cards_spec_spec {
    margin-top: 2px;
    color: #000000;
    text-align: center;
    font-family: 'Montserrat-Medium' !important;
    font-size: 8px;
}

.cards_spec_rec {
    position: absolute;
    height: 2.5vw;
    width: 2.5vw;
    bottom: 5%;
    left: 5%;
    background: #ffffff;
    border: 2px solid #004F9F;
    border-radius: 50px;
}

.cards_spec_rec.rec_true::after {
    position: relative;
    content: '\2713';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: -10%;
}

.cards_title_rec {
    margin-top: 20px;
}

.cards_rec_text {
    height: 100%;
    max-height: 200px;
    background-image: repeating-linear-gradient(white 0px, white 22px, rgb(0, 0, 0) 23px) !important;
}

.cards_rec_text > pre {
    line-height: 23px;
    padding-left: 5px;
    padding-right: 5px;
}

@media screen and (max-width: 1300px) {

    .leftmenu {
        display: none;
    }
}

.cli_sform {
    margin-top: 25px;
}

@media screen and (max-width: 1100px) {

    .pers_list > div {
        width: calc(50% - 30px) !important;
    }

    .fdcolumn {
        flex-direction: column-reverse;
    }

    .fdcolumn > * {
        margin-left: 0 !important;
    }

    .fdcolumn > div:last-child {
        margin-bottom: 20px;
        flex: 0 1 auto !important;
    }

    .fdcolumn > div:first-child {
        flex: 1 1 auto !important;
    }

    .cli_sform {
        margin-top: 0;
    }

    .tworow {
        flex-direction: column;
    }

    .tworow > div {
        width: 100%;
        margin-bottom: 50px;
    }

    .tworow > div:last-of-type {
        margin-bottom: unset;
    }

    .filtershidden {
        display: none;
    }
    .filtersvisible {
        position: unset;
        opacity: 1;
        top: unset;
        width: unset;
        height: unset;
    }
    .filtersmargin {
        margin-right: 0;
    }
}

@media screen and (max-width: 700px) {
    
    .pers_list > div {
        width: 100% !important;
    }

    .space_input {
        flex-direction: column;
    }

    .space_input .ant-space-item, .space_input .ant-select {
        width: 100% !important;
    }

    .cardlist {
        width: 100%;
    }

    .cardlist > * {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {

    .col_24 {
        margin: 0;
    }

    .wcol:last-child:first-child {
        max-width: 100%;
    }
    .wcol {
        margin: 20px 0;
    }
    
    .bg-auth {
        background: url(../src/img/bgm.jpg) fixed no-repeat top center !important;
    }
    
}

@media screen and (max-width: 600px) {

    .custom_tabs .ant-tabs-nav-wrap {
        justify-content: left !important;
    }

    .column-500 {
        flex-direction: column;
    }

    .column-500 > div:first-child {
        margin-bottom: 20px;
    }

    .column-500 > div:last-child {
        text-align: center;
    }

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

@media screen and (max-width: 500px) {

    .floattask > span {
        width: 60px;
        height: 60px;
    }

    .menubutton {
        top: 11px;
        left: 11px;
    }

    .logo {
        height: 40px;
    } 

    .wcol {
        min-width: 100%;
    }
}

.header_title {
    background: rgb(50,110,150);
    background: linear-gradient(165deg, rgba(50,110,150,1) 0%, rgba(87,113,162,1) 100%);
    border-radius: 20px 20px 0 0;
    padding: 15px 30px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-family: 'Manrope-Semibold' !important;
    -webkit-box-shadow: 0px 12px 17px -11px rgba(22, 60, 94, 0.36);
    -moz-box-shadow: 0px 12px 17px -11px rgba(22, 60, 94, 0.36);
    box-shadow: 0px 12px 17px -11px rgba(22, 60, 94, 0.36);
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    border-radius: 40px;
    background: rgb(96,146,187);
background: linear-gradient(30deg, rgba(96,146,187,0.85) 0%, rgba(94,149,185,0.85) 100%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    padding: 50px 50px 50px;
    max-width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0 0 28px 5px rgb(5 44 172 / 25%), 0px 0px 30px 1px rgba(255, 255, 255, 0.26) inset;
}

.authtitle {
    font-family: 'Manrope-Semibold' !important;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
    padding: 0 11px 0 0 !important;
    background: #cfe5f2;
    border-color: #d9d9d9 !important;
}

.inauth input:-webkit-autofill,
.inauth input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

textarea::placeholder, input::placeholder, .ant-select-selection-placeholder {
    color: #000000 !important;
}

.ant-table-cell {
    font-family: 'Manrope-Medium' !important;
}

.inauth input::placeholder {
    color: #5F7B9F;
}

.inauth input {
    font-family: 'Manrope-Semibold' !important;
    caret-color: #5496a1;
    background-color: #cfe5f2;
    color: #5F7B9F !important;
}

.authicon {
    background: #2d4373a1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 38px;
}

.inauths {
    border-radius: 25px;
    width: 100px;
    background: rgb(255 255 255 / 63%) !important;
    border-color: rgb(255 255 255 / 63%) !important;
    color: #5F7B9F !important;
}

.inauths * {
    font-family: 'Manrope-Semibold' !important;
}

.inauths:hover {
    background: rgb(255 255 255 / 53%) !important;
    border-color: rgb(255 255 255 / 53%) !important;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: rgba(212, 212, 212, 100%);
}

::selection {
    background: #dceaff!important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
}

.ant-pagination-item {
    border-radius: 50px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item {
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item a {
    color: rgb(0, 0, 0);
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-color: rgb(0, 0, 0);
}

.ant-pagination-prev .ant-pagination-item-link svg, .ant-pagination-next .ant-pagination-item-link svg {
    fill: rgb(0, 0, 0);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgb(0, 0, 0);
}

.ant-pagination-options .ant-select-selector {
    border: 1px solid rgb(0, 0, 0) !important;
}

.ant-pagination-item-active {
    border-color: #024e79;
    background: #024e79;
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
    color: rgb(0, 0, 0);
}

.ant-pagination-item-active a {
    color: #ffffff;
}

.ant-pagination-item:focus-visible, .ant-pagination-item:hover {
    border-color: rgb(254, 254, 255);
}

.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
    color: rgb(254, 254, 255);
}

.ant-input:hover {
    border-color: rgb(0, 0, 0);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: rgb(0, 0, 0);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgb(0, 0, 0);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #555555;
    color: rgb(254, 254, 255);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed rgb(255, 255, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed rgb(255, 255, 255);
    border-bottom: 1px dashed rgb(255, 255, 255);
}

.ant-picker:hover, .ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 20%);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgb(203, 203, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgb(230, 230, 255);
}

.ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid rgb(0, 0, 0);
}

.ant-btn-dashed:active {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-input:focus, .ant-input-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-radio-checked .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-radio-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-radio-inner::after {
    background-color: rgb(181, 181, 255);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(181, 181, 255);
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgb(181, 181, 255);
}

.bold {
    font-weight: 800;
}

.ant-input, .ant-input-affix-wrapper, .ant-input-number, .ant-modal-content, .ant-picker, .ant-popover-buttons button, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector {
    border-radius: 30px!important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.wowload {
    -webkit-animation: wowload 1s ease;
    animation: wowload 1s ease;
}

.ant-card-head-title {
    overflow: hidden !important;
}

.ant-card-meta-description {
    display: inline-block;
    overflow: hidden;
    overflow-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 50px;
    width: 100%;
}

@keyframes wowload {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

.dempty * {
    font-weight: 600;
    color: #ffffff !important;
}

.column900 {
    align-items: center;
}

.column900 > .ant-col {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-col:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

.column900 > .ant-tabs-content-holder {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-tabs-content-holder:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

.custom_tabs .ant-tabs-nav-wrap {
    justify-content: center;
}

.custom_tabs > .ant-tabs-nav::before {
    content: none;
}

.custom_tabs .ant-tabs-nav-list > .ant-tabs-tab {
    border-radius: 20px !important;
}

.pers_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pers_list > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33% - 30px);
    margin: 15px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 30%);
}

.profile_avatar {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    flex-shrink: 0;
    box-sizing: border-box;
    color: rgba(0,28,61,.08);
    color: rgba(0,28,61,.0784313725490196);
    background: currentColor;
    background-size: cover;
    position: relative;
    margin: auto auto 20px auto;
}

.profile_avatar:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 8%);
}

.profile_avatar > img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: inherit;
    object-fit: cover;
}

.avatar_center > .ant-list-item-meta-avatar {
    margin-top: auto;
    margin-bottom: auto;
}

.ant-select-single .ava_padding {
    height: 22px;
    width: 22px;
}

html {
    --antd-wave-shadow-color: rgb(254, 254, 255);
}


.cal-badge {
    color: #fff;
    background-color: #6c757d;
    padding: .25em .4em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50px;
    display: inline-block;
}

.cal-badge-open {
    background-color: #f8485e;
}

.cal-badge-close {
    background-color: #28a745;
}

.cal-badge-danger {
    background-color: #f8485e;
}

.cal-badge-warning {
    background-color: #ffc107;
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.ant-select-dropdown {
    border-radius: 15px;
    padding: 0;
}

.ant-select-multiple .ant-select-selection-item {
    border-radius: 50px;
}

.space_tools {
    width: 100%;
}

.space_tools > .ant-space-item:nth-child(2) {
    margin-left: auto;
}

.listscroll {
    overflow: auto;
}

.listscroll_script {
    overflow: auto !important;
    max-height: 500px;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #015687;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
@media print {
    .tabsnoprint .ant-tabs-nav {
        display: none;
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}